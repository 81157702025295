const CHAT_ID = "-976700293";
const BOT_TOKEN = "bot6287402773:AAG1Cke1UDTnK4-oa_O-dN6XBBTn6q4P3a4";
const TG_URL = `https://api.telegram.org/${BOT_TOKEN}/sendMessage`;
export async function sendErrorRequest(type: "server" | "client", url: string, err: string) {
  const isDev = process.env.NODE_ENV === "development";
  if (isDev) return;
  const isSplit = url ? url.split(".ru") : "куда-то делся урл";
  const arr = isSplit ? isSplit : url;
  const client = `
    <b>Домен - ${arr[0]}</b>
    <b>Path - ${arr[1]}</b>
  `;
  const server = `
    <b>ошибка - ${err}</b>
  `;
  const message = `
    <b>❌Ошибка 500❌</b>
    <b>Где??? тут - ${type}</b>
    ${isSplit ? client : server}
    <b>Ошибка: ${err}</b>
  `;
  const payload = {
    chat_id: CHAT_ID,
    text: message,
    parse_mode: "html"
  };
  return await fetch(TG_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
}